import dayjs from '@pg/helper/lib/helpers/date-helper';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import { Alert, Button, FormGroup, Grid, Typography, useMediaQuery, useTheme } from '@mui/material';
import {
	ACHForm,
	CCForm,
	PaymentMethodForm,
	WalletForm,
	paymentMethodFormikProps,
} from '@pg/shared-ui';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';

import CreateWalletFormWrapper from './CreateWalletFormWrapper';
import PaymentButtonText from './PaymentButtonText';
import PaymentTotal from './PaymentTotal';

import { convertToUTC, transformPaymentPayload } from '../../api/transformPaymentPayload';
import {
	PAYMENT_PLAN_MESSAGE,
	PAYMENT_PLAN_NO_EMAIL_WARNING,
	isProductionEnv
} from '../../constants';
import { useLoadingState } from '../../loadingContext';
import { useOrgState } from '../../orgContext';
import { makePayment, makePaymentPlan, useAuthDispatch, useAuthState } from '../../userContext';
import {
	PAYMENT_METHODS,
	PAYMENT_PLAN_METHODS,
	UNSUPPORTED_WALLET_PROCESSORS,
} from '../../utils/constants';


const PaymentForm = ( { paymentLocation } ) => {
	const {
		invoiceSelection,
		creditsApplied,
		payment,
		paymentError,
		totalAmount,
		user,
		wallets,
		walletsError,
		walletsLoading,
	} = useAuthState();
	const { org } = useOrgState();
	const { hideLoading, showLoading } = useLoadingState();
	const authDispatch = useAuthDispatch();
	const theme = useTheme();
	const formikRef = useRef();
	const navigate = useNavigate();
	const [ payPlanActive, setPayPlanState ] = useState( false );

	const isXs = useMediaQuery( theme.breakpoints.only( 'xs' ) );

	const { firstName, lastName, email, dob } = user.responsibleParty != null ? user.responsibleParty : user;

	const processors = {
		ach: org.gateway,
		card: org.gateway,
		terminal: org.gateway
	  };
	const disabledWalletType = UNSUPPORTED_WALLET_PROCESSORS.ach?.includes( processors?.ach ) ? 'ach' : '';

	const isPaymentPlanEnabled = useMemo( () => {
		return !creditsApplied?.length && org?.capabilities?.some( capability => capability?.capability === 'csp_payment_plan' && capability?.value === true );
	}, [ org ] );

	const metaData = useMemo( () => {
		try {
			if ( !org ) {
				return {};
			}

			return JSON.parse( org.metaData || '{}' );
		} catch ( error ) {
			throw new Error( `Error parsing metaData: ${ error }` );
		}
	}, [ org ] );

	const filteredWallets = useMemo( () => {
		// filter out unsupported wallet types for payment plans ( like ACH wallet on a nuvei gateway )
		return payPlanActive? wallets?.filter( ( wallet ) => wallet.methodType !== disabledWalletType ) : wallets;
	}, [ payPlanActive, wallets ] );

	const userHasEmail = user?.email || user?.emails?.length;
	const isRP = !user?.hasMatchingResponsibleParty || user?.responsiblePartyId !== null; // Not dependent
	const canSaveCard = !UNSUPPORTED_WALLET_PROCESSORS.cc?.includes( processors?.card ) && org.acceptCards;
	const canSaveAch = !UNSUPPORTED_WALLET_PROCESSORS.ach?.includes( processors?.ach ) && org.acceptAch;

	// Setup form data and initial values using formik
	const prePopulatedValues = {
		firstName: firstName == null ? '' : firstName,
		lastName: lastName == null ? '' : lastName,
		email: email == null ? '' : email,
		dob: dob == null ? '' : dayjs( dob ).format( 'MM/DD/YYYY' )
	};

	const FORMIK_PROPS = {
		initialValues: {
			...paymentMethodFormikProps.initialValues,
			...prePopulatedValues,
			totalAmount: totalAmount.toString(),
		},
		validationSchema: Yup.object(
			{
				...paymentMethodFormikProps.yupValidation,
			}
		)
	};

	if ( filteredWallets?.length > 0 ) {
		FORMIK_PROPS.initialValues.paymentMethod = 'Stored Payment Methods'
		FORMIK_PROPS.initialValues.walletId = filteredWallets[ 0 ].id
		FORMIK_PROPS.initialValues.walletType = filteredWallets[ 0 ].methodType
	}

	const completePayment = async ( values ) => {
		showLoading();
		const payloadPayment = transformPaymentPayload( values, totalAmount.toString(), invoiceSelection, paymentLocation, creditsApplied );

		if ( values?.isPayPlanActive ) {
			await makePaymentPlan( {
				dispatch: authDispatch,
				user,
				values,
				selectedInvoices: invoiceSelection,
				providerId: paymentLocation
			} );
		} else {
			await makePayment( authDispatch, user.authToken, payloadPayment );
		}
	};

	// Coming from invoices, we don't want to be scrolled down on the page, we want to start at the top
	useEffect( () => {
		window.scrollTo( 0, 0 );
	}, [] );

	useEffect( () => {
		hideLoading();
		if ( formikRef?.current?.values?.isPayPlanActive && payment ) {
			const transData = {
				installmentAmount: formikRef.current?.values?.installmentAmount,
				installmentDate: formikRef.current?.values?.paymentStartDate ? convertToUTC( formikRef.current?.values.paymentStartDate ) : undefined,
				organizationName: org?.name || 'Organization'
			};

			// FIXME: btoa is deprecated, use Buffer.from(str, 'base64') and buf.toString('base64')
			const encodedTransData = btoa( JSON.stringify( transData ) );

			const baseUrl = isProductionEnv()
				? 'https://home.payground.com/rs503724s0/'
				: 'https://dev-payground.pantheonsite.io/test/';

			window.location.href = `${baseUrl}?transdata=${encodedTransData}`;
		} else if ( payment ) {
		  navigate( '/success' );
		}
	  }, [ payment ] );

	  useEffect( () => {
		if ( paymentError ) {
		  hideLoading();

		  window.scrollTo( 0, 0 );
		}
	  }, [ paymentError ] );

	return (
		<Formik innerRef={formikRef} onSubmit={completePayment} {...FORMIK_PROPS}>
			{( { values, errors } ) => (
				<Form>
					<FormGroup>
						<PaymentMethodForm
							achForm={
								<CreateWalletFormWrapper
									showButton={values.isPayPlanActive && isRP && canSaveAch}
								>
									<ACHForm
										showSaveWallet={!values.isPayPlanActive && isRP && canSaveAch}
									/>
								</CreateWalletFormWrapper>
							}
							ccForm={
								<CreateWalletFormWrapper
									showButton={values.isPayPlanActive && isRP && canSaveCard}
								>
									<CCForm
										showSaveWallet={!values.isPayPlanActive && isRP && canSaveCard}
									/>
								</CreateWalletFormWrapper>
							}
							walletForm={
								<div>
									{walletsError && (
										<Alert sx={{ mb: 2 }} severity='error'>{walletsError}</Alert>
									)}
									{( filteredWallets.length > 0 || walletsLoading ) &&
											<>
												<WalletForm wallets={filteredWallets} />
												<p>
													This payment method is stored on-file at <strong>{org?.name}</strong>
												</p>
											</>
									}
								</div>
							}
							paymentMethodConfig={{
								platformPMs: PAYMENT_METHODS,
								loadWallets: !!user.contactId && filteredWallets.length > 0,
								processors,
								platformUnsupportedWallets: UNSUPPORTED_WALLET_PROCESSORS,
								orgAcceptsCards: org.acceptCards,
								orgAcceptsAch: org.acceptAch
							}}
							paymentPlanConfig={{
								platformPMs: PAYMENT_PLAN_METHODS,
								emailMissingError: userHasEmail ? '' : PAYMENT_PLAN_NO_EMAIL_WARNING( totalAmount ),
								minBalance: Number( metaData?.payment_plan_minimum_balance ) || 0,
								minPayment: Number( metaData?.payment_plan_minimum_payment ) || 0,
								maxTerm: Number( metaData?.payment_plan_maximum_term ) || 36,
								enabled: isPaymentPlanEnabled,
								paymentPlanMessage: PAYMENT_PLAN_MESSAGE( totalAmount )
							}}
							financeConfig={{
								enabled: org?.financing?.enabled,
								minPayment: org?.financing?.minimum
							}}
							title='Payment Options'
							showPaymentMethodHeader
							setPayPlanState={setPayPlanState}
						/>
						{ ( values.isPayPlanActive && !userHasEmail ) ?
							null
							:
							( <PaymentTotal /> )
						}
						<Grid
							container
							direction='row'
							spacing={1}
						>
							<Grid item xs={12} sm={6} order={{ xs: 2, sm: 1 }}>
								<Button
									color='primary'
									variant='outlined'
									component={Link}
									to='/invoices'
									fullWidth={isXs}
									sx={{ borderRadius: 2, height: '50px', minWidth: '150px' }}
								>
									<Typography variant='button' color={theme.palette.primary.main}>Back</Typography>
								</Button>
							</Grid>
							<Grid item xs={12} sm={6} container justifyContent='flex-end' order={{ xs: 1, sm: 2 }}>
								<Button
									color='primary'
									type='submit'
									variant='contained'
									fullWidth={isXs}
									disabled={!userHasEmail && values.isPayPlanActive || Object.keys( errors ).length > 0}
									sx={{ borderRadius: 2, height: '50px', minWidth: '300px' }}
								>
									<PaymentButtonText
										checkedInvoices={invoiceSelection}
										values={values}
									/>
								</Button>
							</Grid>
						</Grid>
					</FormGroup>
				</Form>
			)}
		</Formik>
	);
};

PaymentForm.propTypes = {
	paymentLocation: PropTypes.string.isRequired,
};

export default PaymentForm;
