import {v4 as uuid} from 'uuid';
import JSEncrypt from 'jsencrypt';
import { removeFalsyProperties } from '@pg/shared-ui';
import { publicKeyRaw } from '../constants';

const encrypt = ( paymentDetails ) => {
	const jsE = new JSEncrypt();
	const publicKey = publicKeyRaw();
	jsE.setPublicKey( publicKey );
	const encrypted = jsE.encrypt( JSON.stringify( paymentDetails ) );

	return { secureData:encrypted };
}

const getWalletPayload = ( values ) => {
	return {
		walletId: values.walletId,
	};
};

const getCardWalletPayload = ( values ) => {
	return {
		creditCard: {
			creditCardNumber: values.cardNum.replace( /-/g, '' ),
			cvv: `${values.cvv}`,
			expiration: values.exp.replace( '/', '' ),
			cardHolderName: values.nameOnCard.trim().substring( 0, 50 ),
			billingAddress: {
				zip: values.zip,
			},
		},
	};
};

const getAchWalletPayload = ( values ) => {
	return {
		ach: {
			accountType: values.accountType?.toUpperCase(),
			accountNumber: values.accountNumber,
			routingNumber: values.routingNum,
			nameOnAccount: values.nameOnAccount?.trim().substring( 0, 50 ),
		},
	};
};

const walletMethodPayload = {
	'Stored Payment Methods': getWalletPayload,
	'Card Payment': getCardWalletPayload,
	'ACH Payment': getAchWalletPayload,
};

export const convertToUTC = ( value ) => {
	const givenDate = new Date( value );
	givenDate.setUTCHours( 12, 0, 0, 0 ); // Set the UTC hours to 12pm

	const isoDate = givenDate.toISOString(); // Convert to ISO formatt: 2023-04-14T12:00:00.000Z
	return isoDate;
}

export const transformCreateWalletPayload = ( { user, values } ) => {
	if ( !user || !values ) return {};

	const { contactId } = user;

	return {
		authToken: user.authToken,
		payload: {
		  contactId,
		  ...( walletMethodPayload[ values.paymentMethod ]( values ) ? walletMethodPayload[ values.paymentMethod ]( values ) : {} ),
		},
	};
};

export const transformPaymentPayload = ( formValues, amount, invoiceIds, location, creditsApplied ) => {
	const createCCPayload = ( values ) => {
		const encryptedPayload = encrypt( {
			cardNumber: values.cardNum.replace( /-/g, '' ),
			cvv: `${values.cvv }`,
			saveCard: values.saveWallet,
			cardExp: values.exp.replace( '/', '' ),
			cardHolderName: values.nameOnCard.trim().substring( 0, 50 ),
		} );

		return {
			...encryptedPayload,
			billingAddress: {
				zip: values.zip
			}
		}
	};

	const createACHPayload = ( values ) => {
		return encrypt( {
			accountType: values.accountType.toUpperCase(),
			accountNumber: values.accountNumber,
			routingNumber: values.routingNum,
			saveToWallet: values.achSaveWallet,
			nameOnAccount: values.nameOnAccount.trim().substring( 0, 50 )
		} );
	};

	const createFinancePayload = ( values ) => {
		return {
			firstName: values.firstName.trim(),
			lastName: values.lastName.trim(),
			email: values.email.trim(),
			dob: values.dob
		};
	};

	const payload = {
		paymentInputId: uuid(),
		amount,
		invoiceIds,
		location,
		creditsApplied
	};

	switch ( formValues.paymentMethod ) {
		case 'Stored Payment Methods':
			payload.walletId = formValues.walletId;
			payload.walletType = formValues.walletType;
			break;
		case 'Card Payment':
			payload.creditCard = createCCPayload( formValues );
			break;
		case 'ACH Payment':
			payload.ach = createACHPayload( formValues );
			break;
		case 'Finance Payment':
			payload.financingDetails = createFinancePayload( formValues );
			break;
		default:
	}

	return payload;
};

export const transformPaymentPlanPayload = ( { user, values, selectedInvoices, providerId } ) => {
	if ( !user || !values ) return {};

	return {
		authToken: user.authToken,
		paymentMethod: values.paymentMethod,
		payload: removeFalsyProperties( {
			amount: {
				installmentAmount: values?.installmentAmount,
				total: ( values?.totalAmountDue - values?.upfrontPayment || 0 ).toFixed( 2 ),
			},
			contactId: user.contactId,
			location: providerId,
			invoiceIds: selectedInvoices,
			organizationId: user.organizationId,
			paymentMethod: walletMethodPayload[ values.paymentMethod ]( values ),
			startDate: values?.paymentStartDate ? convertToUTC( values.paymentStartDate ) : undefined,
		} ),
	}
}
